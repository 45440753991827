// Core variables and mixins
@import 'variables';
@import 'spectre/variables';
@import 'spectre/mixins';

@import 'theme/variables';
@import 'theme/mixins';
@import 'theme/framework';
@import 'theme/typography';
@import 'theme/forms';
@import 'theme/mobile';
@import 'theme/animation';

@import 'theme/header';
@import 'theme/footer';
@import 'theme/menu';

// Extra Skeleton Styling
@import 'theme/blog';
// @import 'theme/onepage';

@import 'fonts';
@import 'utilities';
@import 'components/header';
@import 'components/hero';
@import 'components/features';
@import 'components/text';
@import 'components/team';
@import 'components/gallery';
@import 'components/schedule';
@import 'components/brands';

.table-of-contents.toc {
    box-shadow: none;

    // replace toctitle with css to avoid changes on the plugin itself
    .toctitle {
        display: none;
    }
    &:before {
        content: 'Überblick';
        font-size: larger;
        font-weight: bold;
    }
}

h1, .h1 {
    font-size: 2rem;
    margin-top: 0.8em;
  }

@import "custom";