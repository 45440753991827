.modular-team {
    .avatar {
        height: 5rem;
        width: 5rem;
        margin: auto;
        display: block;
    }
    .name {
        margin-top: 0.3rem;
    }
    .member {
        text-align: center;
        margin-bottom: 1rem;
        line-height: 1rem;
    }
}