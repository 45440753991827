@font-face {
    font-family: 'Varela Round';
    font-style: normal;
    font-weight: 400;
    src: local("Varela Round"), local("VarelaRound-Regular"), 
        url(../fonts/varela-round.woff) format("woff"),
        url(../fonts/varela-round.ttf) format("truetype");
}

@font-face {
    font-family: "Amatic SC";
    src: url("//db.onlinewebfonts.com/t/bc94a100a8065b2c6ffa0f561cdf4fc7.eot");
    src: url("//db.onlinewebfonts.com/t/bc94a100a8065b2c6ffa0f561cdf4fc7.eot?#iefix") format("embedded-opentype"),
        url("//db.onlinewebfonts.com/t/bc94a100a8065b2c6ffa0f561cdf4fc7.woff2") format("woff2"),
        url("//db.onlinewebfonts.com/t/bc94a100a8065b2c6ffa0f561cdf4fc7.woff") format("woff"),
        url("//db.onlinewebfonts.com/t/bc94a100a8065b2c6ffa0f561cdf4fc7.ttf") format("truetype"),
        url("//db.onlinewebfonts.com/t/bc94a100a8065b2c6ffa0f561cdf4fc7.svg#Amatic SC") format("svg");
}