
.modular-features {

  
    &.offset-box {
      .frame-box {
        margin: -3rem (-1rem - $layout-spacing) 3rem;
        padding: 1rem 1rem;
        background: $light-color;
        box-shadow: 0 0 75px 0 rgba($dark-color, 0.1);
      }
    }
  
    &.small {
  
      .columns {
        margin-top: -1rem;
        text-align: center;
      }
  
      .column:hover {
        .feature-icon i {
          color: $primary-color;
        }
      }
  
      .feature-icon {
        display: block;
        justify-content: left;
  
  
        i {
          position: relative;
          display: inherit;
          font-size: 70px;
          margin: 0 auto 1rem;
          transform: none;
          left: auto;
          top: auto;
          color: $gray-color;
          @extend .default-animation;
  
        }
        h5 {
          text-transform: none;
        }
      }
    }
  
    .frame-box {
      padding: 3rem 0;
  
      > p {
        max-width: 600px;
        margin-left: auto;
        margin-right: auto;
      }
    }
  
    .column {
      padding: 1rem;
  
      &:hover {
        .feature-icon {
          color: $gray-color;
          h5 {
            color: $primary-color;
          }
        }
        .feature-content {
          color: $gray-color-dark;
        }
      }
    }

    .feature-icon {
      font-size: 100px;
      height: 100px;
      color: $gray-color-light;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      position: relative;
      margin: 1rem 0;
      @extend .default-animation;
      &:hover {
         text-decoration: none;
      }
      i {
        // position: absolute;
        // left: 50%;
        // top: 50%;
        // transform: translateX(-50%) translateY(-50%);
      }
      h5 {
        line-height: 1;
        z-index: 1;
        // text-transform: uppercase;
        font-weight: 600;
        margin: 0;
        display: block;
        color: $gray-color-dark;
        margin-top: 1rem;
        margin-left: -10%;
        margin-right: -10%;
      }
    }
    .feature-content {
        color: $gray-color;
        margin-left: -10%;
        margin-right: -10%;
        font-size: 0.7rem;
    }
    @include breakpoint(md) {
        .feature-content {
            margin: 0;
        }
    }
  }