.modular-hero {
    // margin-top: $header-height-large;
    #to-start {
        bottom: 3.5rem;
    }
}

.header-fixed .hero {
    background-position: center center;
}

// .hero {
//     padding-top: 8rem;
//     padding-bottom: 10rem;
// }