.cd-schedule {
    position: relative;
    margin: 2em 0;
    ul {
      list-style: none;
      margin: 0;
      li {
        margin: 0;
      }
    }
  }
  
  .cd-schedule::before {
    /* never visible - this is used in js to check the current MQ */
    content: 'mobile';
    display: none;
  }
  
  @media only screen and (min-width: 800px) {
    .cd-schedule {
      width: 100%;
      max-width: 1400px;
      margin: 2em auto;
    }
    .cd-schedule::after {
      clear: both;
      content: "";
      display: block;
    }
    .cd-schedule::before {
      content: 'desktop';
    }
  }
  
  .cd-schedule .timeline {
    display: none;
  }
  
  @media only screen and (min-width: 800px) {
    .cd-schedule .timeline {
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      padding-top: 50px;
    }
    .cd-schedule .timeline li {
      position: relative;
      height: 50px;
    }
    .cd-schedule .timeline li::after {
      /* this is used to create the table horizontal lines */
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 1px;
      background: #d0d0d0;
    }
    .cd-schedule .timeline li:last-of-type::after {
      display: none;
    }
    .cd-schedule .timeline li span {
      display: none;
    }
  }
  
  @media only screen and (min-width: 1000px) {
    .cd-schedule .timeline li::after {
      width: calc(100% - 60px);
      left: 60px;
    }
    .cd-schedule .timeline li span {
      display: inline-block;
      transform: translateY(-50%);
    }
    // .cd-schedule .timeline li:nth-of-type(2n) span {
    //   display: none;
    // }
  }
  
  .cd-schedule .events {
    position: relative;
    z-index: 1;
  }
  
  .cd-schedule .events .events-group {
    margin-bottom: 30px;
  }
  
  .cd-schedule .events .top-info {
    width: 100%;
    padding: 0 5%;
  }
  
  .cd-schedule .events .top-info > span {
    display: inline-block;
    line-height: 1.2;
    margin-bottom: 10px;
    font-weight: bold;
  }
  
  .cd-schedule .events .events-group > ul {
    position: relative;
    padding: 0 5%;
    /* force its children to stay on one line */
    display: flex;
    overflow-x: scroll;
    -webkit-overflow-scrolling: touch;
  }
  
  .cd-schedule .events .events-group > ul::after {
    /* never visible - used to add a right padding to .events-group > ul */
    display: inline-block;
    content: '-';
    width: 1px;
    height: 100%;
    opacity: 0;
    color: transparent;
  }
  
  .cd-schedule .events .single-event {
    /* force them to stay on one line */
    flex-shrink: 0;
    float: left;
    height: 150px;
    width: 70%;
    max-width: 300px;
    box-shadow: inset 0 -3px 0 rgba(0, 0, 0, 0.2);
    margin-right: 20px;
    transition: opacity .2s, background .2s;
    padding: 0.5em;
  }
  
  .cd-schedule .events .single-event:last-of-type {
    margin-right: 5%;
  }
  
  @media only screen and (min-width: 550px) {
    .cd-schedule .events .single-event {
      width: 40%;
    }
  }
  
  @media only screen and (min-width: 800px) {
    .cd-schedule .events {
      float: left;
      width: 100%;
    }
    .cd-schedule .events .events-group {
      width: 14%;
      float: left;
      border: 1px solid #EAEAEA;
      /* reset style */
      margin-bottom: 0;
    }
    .cd-schedule .events .events-group:not(:first-of-type) {
      border-left-width: 0;
    }
    .cd-schedule .events .top-info {
      /* vertically center its content */
      display: table;
      height: 50px;
      border-bottom: 1px solid #EAEAEA;
      /* reset style */
      padding: 0;
    }
    .cd-schedule .events .top-info > span {
      /* vertically center inside its parent */
      display: table-cell;
      vertical-align: middle;
      padding: 0 .5em;
      text-align: center;
      /* reset style */
      font-weight: normal;
      margin-bottom: 0;
    }
    .cd-schedule .events .events-group > ul {
      /* 19 is the number of list items in the .timeline */
      height: 950px;
      /* reset style */
      display: block;
      overflow: visible;
      padding: 0;
    }
    .cd-schedule .events .events-group > ul::after {
      clear: both;
      content: "";
      display: block;
    }
    .cd-schedule .events .events-group > ul::after {
      /* reset style */
      display: none;
    }
    .cd-schedule .events .single-event {
      position: absolute;
      z-index: 3;
      /* top position and height will be set using js */
      width: calc(100% + 2px);
      left: -1px;
      box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1), inset 0 -3px 0 rgba(0, 0, 0, 0.2);
      /* reset style */
      flex-shrink: 1;
      height: auto;
      max-width: none;
      margin-right: 0;
    }
    .cd-schedule .events .single-event a {
      padding: 1.2em;
    }
    .cd-schedule .events .single-event:last-of-type {
      /* reset style */
      margin-right: 0;
    }
    .cd-schedule .events .single-event.selected-event {
      /* the .selected-event class is added when an user select the event */
      visibility: hidden;
    }
  }
  
  @media only screen and (min-width: 1000px) {
    .cd-schedule .events {
      /* 60px is the .timeline element width */
      width: calc(100% - 60px);
      margin-left: 60px;
    }
  }
  
  .cd-schedule.loading .events .single-event {
    /* the class .loading is added by default to the .cd-schedule element
         it is removed as soon as the single events are placed in the schedule plan (using javascript) */
    opacity: 0;
  }
  
  .cd-schedule .event-name,
  .cd-schedule .event-date {
    display: block;
    color: white;
    font-weight: bold;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  
  .cd-schedule .event-name {
    font-size: 0.6rem;
    line-height: 1.2em;
  }
  
  .cd-schedule .event-date {
    /* they are not included in the the HTML but added using JavScript */
    font-size: 0.8em;
    opacity: .7;
    line-height: 1;
    // margin-bottom: .2em;
  }
  .cd-schedule .noTime {
    padding-top:.1em !important;
    .event-date {
      display: none;
    }
  }
  
  .cd-schedule .single-event[data-event="event-1"],
  .cd-schedule [data-event="event-1"] .header-bg {
    /* this is used to set a background color for the event and the modal window */
    background: #577F92;
  }
  
  // .cd-schedule .single-event[data-event="event-1"]:hover {
  //   background: #618da1;
  // }
  
  .cd-schedule .single-event[data-event="event-2"],
  .cd-schedule [data-event="event-2"] .header-bg {
    background: #de7704;
  }
  
  // .cd-schedule .single-event[data-event="event-2"]:hover {
  //   background: #513e63;
  // }
  
  .cd-schedule .single-event[data-event="event-3"],
  .cd-schedule [data-event="event-3"] .header-bg {
    background: #c3c3c3;
  }
  
  // .cd-schedule .single-event[data-event="event-3"]:hover {
  //   background: #b1c4be;
  // }
  
  .cd-schedule .single-event[data-event="event-4"],
  .cd-schedule [data-event="event-4"] .header-bg {
    background: #8fd040;
  }
  
  .cd-schedule .single-event[data-event="event-5"],
  .cd-schedule [data-event="event-5"] .header-bg {
    background: #135656;
  }
  
  .cd-schedule .single-event[data-event="event-6"],
  .cd-schedule [data-event="event-6"] .header-bg {
    background: #26a6af;
  }
  
  .cd-schedule .single-event[data-event="event-7"],
  .cd-schedule [data-event="event-7"] .header-bg {
    background: #899257;
  }
  
  // .cd-schedule .single-event[data-event="event-4"]:hover {
  //   background: #f7bd7f;
  // }
  
  .cd-schedule .event-modal {
    position: fixed;
    z-index: 3;
    top: 0;
    right: 0;
    height: 100%;
    width: 100%;
    visibility: hidden;
    /* Force Hardware acceleration */
    transform: translateZ(0);
    transform: translateX(100%);
    transition: transform .4s, visibility .4s;
    transition-timing-function: cubic-bezier(0.5, 0, 0.1, 1);
  }
  
  .cd-schedule .event-modal .header {
    position: relative;
    height: 70px;
    /* vertically center its content */
    display: table;
    width: 100%;
  }
  
  .cd-schedule .event-modal .header .content {
    position: relative;
    z-index: 3;
    /* vertically center inside its parent */
    display: table-cell;
    vertical-align: middle;
    padding: .6em 5%;
  }
  
  .cd-schedule .event-modal .body {
    position: relative;
    width: 100%;
    /* 70px is the .header height */
    height: calc(100% - 70px);
  }
  
  .cd-schedule .event-modal .event-info {
    position: relative;
    z-index: 2;
    line-height: 1.4;
    height: 100%;
    overflow: hidden;
  }
  
  .cd-schedule .event-modal .event-info > div {
    overflow: auto;
    height: 100%;
    padding: 1.4em 5%;
  }
  
  .cd-schedule .event-modal .header-bg, .cd-schedule .event-modal .body-bg {
    /* these are the morphing backgrounds - visible on desktop only */
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
  }
  
  .cd-schedule .event-modal .body-bg {
    z-index: 1;
    background: white;
    transform-origin: top left;
  }
  
  .cd-schedule .event-modal .header-bg {
    z-index: 2;
    transform-origin: top center;
  }
  
  .cd-schedule .event-modal .close {
    /* this is the 'X' icon */
    position: absolute;
    top: 0;
    right: 0;
    z-index: 3;
    background: rgba(0, 0, 0, 0.1);
    /* replace text with icon */
    color: transparent;
    white-space: nowrap;
    text-indent: 100%;
    height: 70px;
    width: 70px;
  }
  
  .cd-schedule .event-modal .close::before, .cd-schedule .event-modal .close::after {
    /* these are the two lines of the 'X' icon */
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: 2px;
    height: 22px;
    background: white;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
  }
  
  .cd-schedule .event-modal .close::before {
    transform: translateX(-50%) translateY(-50%) rotate(45deg);
  }
  
  .cd-schedule .event-modal .close::after {
    transform: translateX(-50%) translateY(-50%) rotate(-45deg);
  }
  
  .cd-schedule .event-modal .event-date {
    display: none;
  }
  
  .cd-schedule .event-modal.no-transition {
    transition: none;
  }
  
  .cd-schedule .event-modal.no-transition .header-bg, .cd-schedule .event-modal.no-transition .body-bg {
    transition: none;
  }
  
  @media only screen and (min-width: 800px) {
    .cd-schedule .event-modal {
      /* reset style */
      right: auto;
      width: auto;
      height: auto;
      transform: translateX(0);
      will-change: transform, width, height;
      transition: height .4s, width .4s, transform .4s, visibility .4s;
      transition-timing-function: cubic-bezier(0.5, 0, 0.1, 1);
    }
    .cd-schedule .event-modal .header {
      position: absolute;
      display: block;
      top: 0;
      left: 0;
      height: 100%;
    }
    .cd-schedule .event-modal .header .content {
      /* reset style */
      display: block;
      padding: .8em;
    }
    .cd-schedule .event-modal .event-info > div {
      padding: 2em 3em 2em 2em;
    }
    .cd-schedule .event-modal .body {
      height: 100%;
      width: auto;
    }
    .cd-schedule .event-modal .header-bg, .cd-schedule .event-modal .body-bg {
      /* Force Hardware acceleration */
      transform: translateZ(0);
      will-change: transform;
      backface-visibility: hidden;
    }
    .cd-schedule .event-modal .header-bg {
      transition: transform .4s;
      transition-timing-function: cubic-bezier(0.5, 0, 0.1, 1);
    }
    .cd-schedule .event-modal .body-bg {
      opacity: 0;
      transform: none;
    }
    .cd-schedule .event-modal .event-date {
      display: block;
    }
    .cd-schedule .event-modal .close, .cd-schedule .event-modal .event-info {
      opacity: 0;
    }
    .cd-schedule .event-modal .close {
      width: 40px;
      height: 40px;
      background: transparent;
    }
    .cd-schedule .event-modal .close::after, .cd-schedule .event-modal .close::before {
      background: #222222;
      height: 16px;
    }
  }
  
  @media only screen and (min-width: 1000px) {
    .cd-schedule .event-modal .header .content {
      padding: 1.2em;
    }
  }
  
  .cd-schedule.modal-is-open .event-modal {
    /* .modal-is-open class is added as soon as an event is selected */
    transform: translateX(0);
    visibility: visible;
  }
  
  .cd-schedule.modal-is-open .event-modal .event-info > div {
    /* smooth scroll on iOS touch devices */
    -webkit-overflow-scrolling: touch;
  }
  
  @media only screen and (min-width: 800px) {
    .cd-schedule.animation-completed .event-modal .close,
    .cd-schedule.content-loaded.animation-completed .event-modal .event-info {
      /* 	the .animation-completed class is added when the modal animation is completed
              the .content-loaded class is added when the modal content has been loaded (using ajax) */
      opacity: 1;
      transition: opacity .2s;
    }
    .cd-schedule.modal-is-open .body-bg {
      opacity: 1;
      transition: transform .4s;
      transition-timing-function: cubic-bezier(0.5, 0, 0.1, 1);
    }
  }
  
  .cd-schedule .cover-layer {
    /* layer between the content and the modal window */
    position: fixed;
    z-index: 2;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background: rgba(0, 0, 0, 0.8);
    opacity: 0;
    visibility: hidden;
    transition: opacity .4s, visibility .4s;
  }
  
  .cd-schedule.modal-is-open .cover-layer {
    opacity: 1;
    visibility: visible;
  }
  