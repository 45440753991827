.justify-content-center {
    justify-content: center;
}

.bg-white {
    background: $bg-color-light;
}

.text-small {
    font-size: 80%;
}