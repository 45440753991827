.modular-brands {
    .brand {
        display: flex;
        flex-direction: column;
        justify-content: center;
        a {
            width: 60%;
            margin: auto;
        }
    }
}