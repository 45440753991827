
#header {
    .logo {
        img {
          @extend .default-animation;
          height: 3em;
          margin-bottom: -1em;
          display: inline-block;
        }
        .pagetitle {
          @extend .default-animation;
          font-size: 1.5em;
          font-family: $title-font-family;
          margin-left: 0.2em;
        }
      }
}

// Animate Fixed Header
body.header-fixed.header-animated {
    #header.scrolled {
      .logo img {
        height: 2em;
        margin-bottom: -0.5em;
      }
      .logo .pagetitle {
        font-size: 1.2em;
      }
    }
  }

#page-wrapper {
    margin-top: #{$header-height-large*0.8};
}
.header-fixed #body-wrapper {
  padding: 0;
}

.dropmenu > ul > li.divider {
  padding-top: 0.5em;
  &::before {
    content: '';
    border-right: 1px solid $border-color-dark;
    height: 0.5em;
  }
}